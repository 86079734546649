<template>
  <div class="form-group" :class="newClass">
    <label
      class="form-group__label"
      :class="[checkRequired, newClass]"
      :for="id"
      >{{ label }} <slot name="popover"></slot>
    </label>
    <input
      :type="type"
      class="form-control"
      :class="checkValidation"
      :name="name"
      @input="emit"
      :maxlength="maxLengthing"
      @change="emit"
      :disabled="disabled"
      v-model="val"
      :placeholder="placeholder"
      :tabindex="noTabindex ? -1 : null"
      @blur="emitBlur"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: ""
    };
  },
  computed: {
    checkRequired() {
      return this.required ? "required-label" : "";
    },
    checkValidation() {
      let classes = this.inputClass;

      if (this.isNeedValidation) {
        return (this.val && this.val.length) || typeof this.val === "number"
          ? classes + " valid"
          : classes + " invalid";
      }

      return classes;
    }
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    isNeedValidation: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean
    },
    name: {
      type: String,
      default: "inp"
    },
    type: {
      type: String,
      default: "text"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String],
      default: ""
    },
    id: {
      type: [Number, String]
    },
    newClass: {
      type: String,
      default: "form-group_other"
    },
    inputClass: {
      type: String,
      default: ""
    },
    maxLengthing: {
      type: [Number, String]
    },
    isShowPopover: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    noTabindex: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emit() {
      if (this.type === "checkbox") {
        this.$emit("change", this.val);
      } else {
        this.$emit("input", this.val);
      }
    },
    emitBlur() {
      this.$emit("blur");
    }
  },
  watch: {
    value() {
      if (this.value === "null") {
        return "";
      }
      this.val = this.value;
    }
  },
  created() {
    this.val = this.value;
  }
};
</script>

<style lang="scss" scoped>
.form-group__label {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--main-text-color);
  position: relative;
}
.form-group__label.over-top {
  //color: #fff !important;
}

.required-label:after {
  content: "*";
  font-size: 26px;
  color: red;
  position: absolute;
}

.form-control {
  background: var(--auth-input-bg);
  border: 1px solid var(--auth-input-bg);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
  color: var(--main-text-color);
}

.push-form__input {
  font-size: 20px;
  line-height: 18px;

  text-align: center;
  letter-spacing: -0.165px;

  color: #58585e;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: var(--auth-input-bg);
  opacity: 1;
}
</style>
