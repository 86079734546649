<template>
  <form class="change_password" @submit.prevent="onSubmit">
    <h4 class="d-flex justify-content-center change_password-title">
      Изменение пароля
    </h4>

    <div>
      <label class="form-control__label">Введите старый пароль</label>
      <b-input
        v-model="oldPassword"
        type="password"
        class="form-control__input"
      />
    </div>

    <div class="mt-3">
      <label class="form-control__label">Введите новый пароль</label>
      <b-input
        v-model="newPassword"
        placeholder="Не менее 3х символов"
        type="password"
        class="form-control__input"
        :state="passwordLength"
      />
    </div>

    <div class="mt-3">
      <label class="form-control__label">Подтвердите новый пароль</label>
      <b-input
        v-model="acceptNewPassword"
        type="password"
        placeholder="Повторите новый пароль"
        class="form-control__input"
        :state="passwordIdent"
      />
      <b-form-invalid-feedback>
        Введенные пароли не совпадают
      </b-form-invalid-feedback>
    </div>

    <div
      class="d-flex justify-content-center align-items-center mt-4 flex-column"
    >
      <b-button
        type="submit"
        class="btn btn-second w-100"
        :disabled="!isDisabledButton"
      >
        <span v-if="!loading">Подтвердить</span>
        <b-spinner v-else small></b-spinner>
      </b-button>

      <a href="javascript:void(0)" class="mt-4" @click="$emit('closeForm')"
        >Отмена</a
      >
    </div>
  </form>
</template>

<script>
export default {
  name: "ChangeUserPassword",
  props: {
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      acceptNewPassword: ""
    };
  },
  computed: {
    passwordLength() {
      return this.newPassword ? this.newPassword.length >= 3 : null;
    },
    passwordIdent() {
      return this.acceptNewPassword
        ? this.acceptNewPassword === this.newPassword
        : null;
    },
    isDisabledButton() {
      return (
        !!this.oldPassword &&
        !!this.newPassword &&
        !!this.acceptNewPassword &&
        this.newPassword === this.acceptNewPassword
      );
    }
  },
  methods: {
    onSubmit() {
      const passwordData = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        acceptNewPassword: this.acceptNewPassword
      };
      this.$emit("accept", passwordData);
    }
  }
};
</script>

<style lang="scss">
.change_password {
  &-title {
    margin-bottom: 28px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #4b4b67;
  }

  .form-control__input {
    background-color: rgba(206, 206, 206, 0.2);
    border: 1px solid rgba(206, 206, 206, 0.2);
    border-radius: 6px;
    min-height: 56px;
  }
  .form-control__label {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: var(--main-text-color);
    position: relative;
    margin-bottom: 0.5rem;
  }
}
</style>
