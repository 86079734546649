<template>
  <TepmplateBlock mod-class content-class="mb-5" title-page="Редактировать">
    <div class="scroll-container">
      <vuescroll>
        <div class="edit-user pb-4">
          <div v-show="!isShowChangePasswordForm">
            <form @submit.prevent="onSubmit">
              <div class="edit-user__img-load-wrap">
                <label class="file-select">
                  <div v-if="!user.profile_picture" class="select-button"></div>
                  <img
                    v-else
                    class="preview-image"
                    :src="src ? src : user.profile_picture"
                    alt=""
                  />
                  <!--          <span v-if="value">{{ value.name }}</span>-->
                  <!--          <span v-else>Загрузите фотографию устройства</span>-->
                  <input type="file" @change="handleFileChange" />
                </label>
              </div>

              <a
                class="d-flex justify-content-center mt-3 mb-3"
                href="javascript:void(0)"
                @click="isShowChangePasswordForm = true"
                >Изменить пароль</a
              >

              <Input v-model="user.first_name" label="Имя" />
              <Input v-model="user.last_name" label="Фамилия" />

              <FormSelect
                @chosen="setOffice"
                :default="user.office ? user.office.toString() : ''"
                title="Офис"
                :options="officeOptions"
              />

              <FormSelect
                v-if="currentUser.id !== user.id"
                @chosen="setRole"
                :default="user.role ? user.role.toString() : ''"
                title="Роль"
                :options="rolesOptions"
              />
              <!-- <label class="form-group__label phone-label">Телефон</label>
              <div class="edit-user__select-group">
                <FormSelect
                  title=""
                  class="page-select"
                  :options="options"
                  @chosen="onCodeSelect"
                  :default="defaultPhoneCode"
                />

                <Input v-model="phoneNumber" label="" />
              </div> -->
              <div class="form-group">
                <label
                  class="form-group__label phone-label"
                  :class="isIntroPassed === 'false' ? 'over-top' : ''"
                  >Телефон</label
                >
                <input
                  type="text"
                  class="form-control phone"
                  :class="{
                    invalid: phoneNumber && phoneNumber.length < 9,
                    'over-top': isIntroPassed === 'false' && !isPhoneExist
                  }"
                  v-model="phoneNumber"
                  v-phone
                />
              </div>

              <div class="edit-user__btn-group">
                <!-- <router-link
                  type="button"
                  to="/colleagues-contacts"
                  class="btn btn-primary edit-user__btn-prev"
                >
                  Назад
                </router-link> -->

                <span
                  class="btn btn-primary edit-user__btn-prev"
                  @click="onGoBack"
                >
                  Назад
                </span>

                <button type="submit" class="btn btn-second">
                  Сохранить
                </button>
              </div>
            </form>
          </div>

          <ChangeUserPassword
            v-if="isShowChangePasswordForm"
            @accept="changePassword($event)"
            @closeForm="isShowChangePasswordForm = false"
            class="pb-3"
          />
        </div>
      </vuescroll>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import FormSelect from "../../components/Form/FormSelect";
import Input from "../../components/Form/FormElement/Input";
import RequestManager from "../../function/request/RequestManager";
import { GET_USER_ROLES, GET_ALL_OFFICES } from "@/store/types";
import { mapGetters, mapActions } from "vuex";
import ChangeUserPassword from "../../components/ChangeUserPassword.vue";
import vuescroll from "vuescroll";
import { OfficeApi } from "../../function/request/apiV2";

export default {
  name: "EditUser",
  components: {
    FormSelect,
    Input,
    TepmplateBlock,
    ChangeUserPassword,
    vuescroll
  },
  computed: {
    //...mapGetters(["officeList", "userRoles", "currentUser"]),
    ...mapGetters(["userRoles", "currentUser"]),
    officeOptions() {
      console.log("officeOptions, officeList=", this.officeList);
      if (this.officeList && this.officeList.count > 0) {
        return this.officeList.items.map(office => {
          return { value: office.id, text: office.name };
        });
      } else {
        return [];
      }
    },
    rolesOptions() {
      if (this.userRoles !== undefined) {
        const options = [];
        for (let key in this.userRoles) {
          options.push({
            text: this.userRoles[key] === "client_admin" ? "Админ" : "Менеджер",
            value: key
          });
        }
        return options;
      }
      return [];
    }
  },
  watch: {
    src: function(value) {
      if (value) {
        const formData = new FormData();
        formData.append("profile_picture", this.user.profile_picture);
        RequestManager().updateUserPhoto(this.user.id, formData);
      }
    }
  },
  created() {
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
    RequestManager()
      .getSingleUser(this.$route.params.id)
      .then(data => {
        for (let key in data) {
          this.user[key] = data[key];
          if (key === "phone_number" && this.user[key].length > 0) {
            this.defaultPhoneCode = this.user[key].slice(0, 2);
            this.phoneNumber = this.user[key];
          }
        }
      });
  },
  async mounted() {
    this.GET_USER_ROLES();
    this.GET_ALL_OFFICES();
    const itemsOffices = await this.officeApi.api.listOffice({
      companyIds: [this.$route.params.companyId],
      limit: 1000000,
      sort: "name"
    });
    this.officeList = itemsOffices;
    console.log("List offices:", this.officeList);
  },
  data() {
    return {
      isShowChangePasswordForm: false,
      defaultPhoneCode: "",
      phoneCode: "",
      phoneNumber: "",
      selected: "Админ",
      options: [{ text: "+ 7", value: "+7" }],
      src: null,
      officeApi: new OfficeApi(),
      officeList: {},
      user: {
        id: "",
        first_name: "",
        last_name: "",
        company: "",
        office: "",
        profile_picture: "",
        email: "",
        role: "",
        access: {
          mytech: true,
          qrcode: false,
          myaccount: false,
          mycalendar: false,
          myrequests: false
        }
      },
      isIntroPassed: undefined
    };
  },
  methods: {
    ...mapActions({ GET_USER_ROLES, GET_ALL_OFFICES }),
    onGoBack() {
      this.$router.go(-1);
    },
    changePassword(passwordData) {
      RequestManager()
        .changeUserPassword(
          this.$route.params.id,
          passwordData.oldPassword,
          passwordData.newPassword
        )
        .then(res => {
          if (res?.success === false) {
            const invalidOldPasswordMessage = "Неверный текущий пароль";
            this.$bvToast.toast(
              res.logError?.response?.data?.detail === "Invalid old password"
                ? invalidOldPasswordMessage
                : "Возникла непредвиденная ошибка",
              {
                variant: "danger",
                title: "Ошибка"
              }
            );
          } else {
            this.isShowChangePasswordForm = false;
            this.$bvToast.toast("Пароль успешно изменен", {
              variant: "success",
              title: "Успешно"
            });
          }
        })
        .catch(e => {
          throw new Error(e);
        });
    },
    setOffice(value) {
      this.user.office = value;
    },
    onCodeSelect(code) {
      this.phoneCode = code;
      this.error = "";
    },
    setRole(value) {
      this.user.role = value;
    },
    handleFileChange(e) {
      const src = URL.createObjectURL(e.target.files[0]);
      console.log(src);
      this.user.profile_picture = e.target.files[0];
      this.$set(this, "src", src);
    },
    onSubmit() {
      delete this.user.profile_picture;
      const phone_number = this.phoneNumber
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("-", "")
        .replaceAll(" ", "");
      RequestManager()
        .updateUser(this.user.id, { ...this.user, phone_number })
        .then(() => {
          this.$router.push({ path: "/colleagues-contacts" });
        });
    },
    onChange(field) {
      this.user.access[field] = !this.user.access[field];
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-user__select-group {
  display: flex;
  align-items: center;
  max-height: 65px;
  margin-bottom: 20px;

  .page-select {
    position: relative;
    top: -2px;
  }
}
.create-user__checkbox-wrap {
  width: 50%;

  &:not(:last-child) {
    margin-bottom: 27px;
  }
}
.preview-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  width: 97px;
  height: 97px;
}
.edit-user {
  position: relative;
  margin: 0 auto;
  padding: 38px 72px 0;
  width: 100%;
  min-width: 498px;
  max-width: 498px;

  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
}

.form-group__label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  color: var(--main-text-color) !important;
}

.edit-user__img-load-wrap {
  margin-bottom: 30px;
}

.edit-user__btn-group {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.edit-user__btn {
  max-width: 100%;
}

.edit-user__btn-prev {
  border: 1px solid #006b90;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  margin-right: 7px;
}

.file-select {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 14px;

  color: #1f1f1f;
}

.file-select > .select-button {
  background-image: url("../../resource/img/edit-ava.svg");
  background-repeat: no-repeat;
  font-size: 10px;
  width: 97px;
  height: 97px;
  cursor: pointer;
  margin-right: 17px;
}

.file-select > input[type="file"] {
  display: none;
}

.edit-user__radio-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  //justify-content: space-between;
}

.edit-user__label-wrap {
  &:not(:last-child) {
    margin-right: 105px;
  }
}

.edit-user__label {
  margin-left: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #4b4b67;
}

.edit-user__legend {
  margin-top: 32px;
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;

  color: #4b4b67;
}

.edit-user__radio {
  display: none;
}

.edit-user__label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 0;
  line-height: 18px;
  user-select: none;

  &::before {
    content: "";
    display: inline-block;
    width: 23px;
    height: 23px;
    position: absolute;
    left: -11px;
    bottom: 0;
    background: #ffffff;
    border: 2px solid #006b90;
    border-radius: 50%;
  }
}

.edit-user__radio:checked + .edit-user__label::before {
  position: absolute;
  left: -5px;
  bottom: 5px;
  border: 1px solid #006b90;
  background: #006b90;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  z-index: 2;
}

.edit-user__radio:checked + .edit-user__label::after {
  content: "";
  position: absolute;
  left: -11px;
  bottom: 0;
  border: 2px solid #006b90;
  background: #ffffff;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

/* Hover */

.edit-user__label:hover::before {
  filter: brightness(120%);
}

/* Disabled */

.edit-user__radio:disabled + edit-user__label::before {
  filter: grayscale(100%);
}

.form-control {
  background-color: var(--main-card-color);
  &:focus {
    background-color: var(--main-card-color);
    color: var(--main-text-color);
  }
}

.custom-select {
  background-color: var(--main-card-color);
  color: var(--main-text-color);
  &:focus {
    background-color: var(--main-card-color);
  }
}

.phone-label {
  font-size: 14px;
  font-weight: 500;
}
.phone-label.over-top {
  color: #fff !important;
}
.phone {
  background: var(--auth-input-bg);
  border: 1px solid var(--auth-input-bg);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
  color: var(--main-text-color);
}
</style>
