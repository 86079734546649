<template>
  <div>
    <b-form-text class="add-item__select-wrap mb-2 form-group__label">
      <span :class="checkRequired"
        >{{ title }}
        <slot name="title-append"></slot>
      </span>
    </b-form-text>
    <b-form-select
      class="add-item__select mb-3 select"
      v-model="selected"
      :options="options"
      :disabled="disabled === true ? true : false"
      :class="checkValidation"
    ></b-form-select>
  </div>
</template>

<script>
export default {
  name: "FormSelect",
  computed: {
    checkRequired() {
      return this.required ? "required-label" : "";
    },
    checkValidation() {
      let classes = "";

      if (this.isNeedValidation) {
        return this.selected ? (classes = "valid") : (classes = "invalid");
      }

      return classes;
    }
  },
  watch: {
    default(value) {
      if (value !== undefined) {
        this.selected = value;
      }
    },
    selected(value) {
      this.$emit("chosen", value);
    }
  },
  data() {
    return {
      selected: null
    };
  },
  props: {
    title: String,
    options: Array,
    default: String,
    disabled: Boolean,
    required: {
      type: Boolean,
      default: false
    },
    isNeedValidation: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.select {
  min-width: 75px;
}
.add-item__select-wrap,
.form-group__label {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--second-main-color) !important;

  &::after {
    content: "";
    position: absolute;
    right: 18px;
    top: 38px;
    width: 24px;
    height: 24px;
    background-image: url("../../resource/img/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.add-item__select {
  position: relative;
  padding: 10px 22px 14px 15px;
  height: 56px;
  background: rgba(206, 206, 206, 0.2);
  border: 1px solid #cecece;
  box-sizing: border-box;
  border-radius: 6px;
}

.form-group__label span {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--main-text-color);
  position: relative;
}

.required-label:after {
  content: "*";
  font-size: 26px;
  color: red;
  position: absolute;
}

.select {
  background: var(--auth-input-bg);
  border: 1px solid rgba(206, 206, 206, 0.2);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
}

.select.invalid {
  border: 1px rgb(245, 0, 0) solid;
}

.select.invalid:focus {
  box-shadow: 0 0 0 3px rgba(245, 0, 0, 0.2);
}

.select.valid {
  border: 1px rgb(40, 167, 69) solid;
}

.select.valid:focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.2);
}
</style>
